import React, { FC, useState } from 'react'
import { observer } from 'mobx-react-lite'

import { Button, CenteredContainer, ClearButton, Container, Input, Text, Title } from './shared'
import { useMst } from '../../models/Root'
import { AuthModalPhase } from '../../models/AuthStore'
import { InputError } from '../../types'
import { isEmail } from '../../utils/inputValidation'
import { ExternalSignIn } from './ExternalSignIn'
import { AuthError } from './AuthError'

type Props = {}

export const Login: FC<Props> = observer(() => {
  const {
    authStore: { setPhase, login, fetching }
  } = useMst()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [errors, setErrors] = useState<InputError[]>([])

  const validateInputs = () => {
    setEmail(email.trim())
    let validationErrors: InputError[] = []

    if (email.trim().length && !isEmail(email.trim())) {
      validationErrors.push({ input: 'email', message: 'Tarkista sähköpostiosoite' })
    }
    setErrors(validationErrors)
    return validationErrors.length
  }

  const handleLogin = () => {
    const hasErrors = validateInputs()
    if (hasErrors) return
    login({ email, password, provider: undefined })
  }
  const handleForgotPasswordClick = () => setPhase(AuthModalPhase.ForgotPassword)
  const handleRegisterClick = () => setPhase(AuthModalPhase.Register)

  const disabled = !email.length || !password.length || !!errors.length || fetching

  return (
    <Container>
      <CenteredContainer>
        <Title>Kirjaudu sisään</Title>
      </CenteredContainer>
      <Text>Kirjaudu sisään luomillasi Dei Plus -tunnuksilla.</Text>
      <Input
        type="email"
        label="Sähköposti"
        value={email}
        onChange={setEmail}
        onBlur={validateInputs}
        errorMessage={errors.find((err) => err.input === 'email')?.message}
      />
      <Input
      type="password"
      label="Salasana"
      value={password}
      onChange={setPassword}
      onBlur={validateInputs}
      errorMessage={errors.find((err) => err.input === 'password')?.message}
      />
      <ClearButton onClick={handleForgotPasswordClick}>Olen unohtanut salasanani</ClearButton>
      <AuthError />
      <Button fetching={fetching} onClick={handleLogin} disabled={disabled}>
        Kirjaudu sisään
      </Button>
      <CenteredContainer>
        <Text>Jos sinulla ei vielä ole tunnusta</Text>
        <ClearButton onClick={handleRegisterClick}>Luo käyttäjätunnus</ClearButton>
      </CenteredContainer>
      <ExternalSignIn />
    </Container>
  )
})
