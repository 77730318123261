import { useContext, createContext } from 'react'
import { types, Instance } from 'mobx-state-tree'
import makeInspectable from 'mobx-devtools-mst'

import { RADIO_CHANNEL_MEDIA_ID } from '../components/Category/utils'

import { MediaPlayer } from './MediaPlayer'
import { CategoryStore } from './Category'
import { FavoritesStore } from './Favorites'
import { SearchStore } from './Search'
import { MenuStore } from './Menu'
import { AuthStore } from './AuthStore'
import { UserStore } from './User'
import { ProgressStore } from './Progress'

const RootModel = types.model('RootStore', {
  mediaPlayer: MediaPlayer,
  category: CategoryStore,
  favorites: FavoritesStore,
  search: SearchStore,
  menu: MenuStore,
  authStore: AuthStore,
  userStore: UserStore,
  progress: ProgressStore
})

export const rootStore = RootModel.create({
  mediaPlayer: {
    isPlaying: false,
    mediaId: RADIO_CHANNEL_MEDIA_ID,
    url: 'https://stream.dei.fi:8443/yleisohjelma',
    isLive: true,
    liveStreamIndex: 0,
    liveStreamLabel: 'Kuuntele Radio Deitä',
    pauseRequested: false
  },
  category: {
    state: 'Initial'
  },
  favorites: {
    state: 'Initial',
    needToFetch: true
  },
  search: {
    searchResults: []
  },
  menu: {},
  authStore: {},
  userStore: {},
  progress: {
    state: 'Initial'
  }
})

makeInspectable(rootStore)

// onSnapshot(rootStore, snapshot => console.log("Snapshot: ", snapshot));

export interface RootInstance extends Instance<typeof RootModel> {}
const RootStoreContext = createContext<null | RootInstance>(null)

export const StoreProvider = RootStoreContext.Provider
export function useMst() {
  const store = useContext(RootStoreContext)

  if (!store) {
    throw new Error('No context for store')
  }

  return store
}
