import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { useMst } from '../../models/Root'
import Favorite from '../../resources/icons/favorite-star-icon.svg'
import { colors, breakpoints } from '../../styles/variables'

const FavoriteButtonContainer = styled.div.attrs((props: { marginTop: number; inverse: boolean }) => props)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 43px;
  background: ${(props) => (props.inverse ? colors.white : colors.black)};
  cursor: pointer;
  margin-top: ${(props) => props.marginTop || '0'}px;
  padding: 12px 30px;
`

const Text = styled.div.attrs((props: { inverse: boolean; favorited: boolean }) => props)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  margin-left: 14px;
  color: ${(props) => (props.favorited ? colors.deiRed : props.inverse ? colors.black : colors.white)};
  margin-left: 20px;

  @media ${`(min-width: ${breakpoints.md}px)`} {
    font-size: 18px;
    line-height: 22px;
    margin-left: 5px;
  }
`

interface Props {
  id: number
  series: boolean
  marginTop?: number
  inverse?: boolean
}

const FavoriteButton: React.FC<Props> = observer(({ id, series, marginTop, inverse }) => {
  const {
    favorites: { checkIfIdFavorited, favoriteById, localFavorites, unFavoriteById }
  } = useMst()
  const [areWeFavorited, setAreWeFavorited] = useState(checkIfIdFavorited(id))

  useEffect(() => {
    setAreWeFavorited(checkIfIdFavorited(id))
  }, [localFavorites.length, id])

  const buttonClickHandler = () => {
    console.log('FAVO CLICKED! LETS DO THIS')

    if (!areWeFavorited) {
      console.log('We are not favorited, therefore we must become one')
      favoriteById(id, series)
      setAreWeFavorited(true)
    } else {
      console.log('We are already favorited, we need to become not so favorited')
      unFavoriteById(id)
      setAreWeFavorited(false)
    }
  }

  return (
    <FavoriteButtonContainer onClick={buttonClickHandler} marginTop={marginTop} inverse={inverse}>
      <Favorite fill={areWeFavorited ? colors.deiRed : inverse ? colors.black : colors.white} />
      <Text inverse={inverse} favorited={areWeFavorited}>
        {areWeFavorited ? 'Poista suosikeista' : 'Lisää omiin suosikkeihin'}
      </Text>
    </FavoriteButtonContainer>
  )
})

export default FavoriteButton
