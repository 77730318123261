import React, { createContext, useContext, useState } from 'react'

interface VideoPlayerContextValue {
  videoOpen: boolean
  setVideoOpen: (value: boolean) => void
  videoUrl: string | null
  setVideoUrl: (value: string | null) => void
}

const VideoPlayerContext = createContext<VideoPlayerContextValue>({
  videoOpen: false,
  setVideoOpen: () => {},
  videoUrl: null,
  setVideoUrl: () => {}
})

interface VideoPlayerProviderProps {
  children: React.ReactNode
}

export const useVideoPlayer = () => useContext(VideoPlayerContext)

export const VideoPlayerProvider: React.FC<VideoPlayerProviderProps> = ({ children }) => {
  const [videoOpen, setVideoOpen] = useState(false)
  const [videoUrl, setVideoUrl] = useState<string | null>(null)

  return <VideoPlayerContext.Provider value={{ videoOpen, setVideoOpen, videoUrl, setVideoUrl }}>{children}</VideoPlayerContext.Provider>
}
