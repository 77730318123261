export enum StorageKey {
  favorites = 'FAVORITES',
  progress = 'PROGRESS',
  poptrack = 'POPTRACK',
  accessToken = 'ACCESS_TOKEN'
}

interface StorageProps {
  key: StorageKey
}

interface WriteStorageProps extends StorageProps {
  value: Object | null
}

const read = ({ key }: StorageProps) => {
  if (typeof window !== undefined && window.localStorage) {
    const value = window.localStorage.getItem(key)
    if (value) return JSON.parse(value)
  }
}

const write = ({ key, value }: WriteStorageProps) => {
  if (typeof window !== undefined && window.localStorage) window.localStorage.setItem(key, JSON.stringify(value))
}

const STORAGE = {
  read,
  write
}

export default STORAGE
