import React, { FC } from 'react'
import styled from 'styled-components'
import { CircularProgress } from '@material-ui/core'
import { MediaFile } from '../../types'
import { breakpoints, colors, fonts } from '../../styles/variables'
import { MobileMediaPlayer } from './MobileMediaPlayer'

const CloseButton = styled.button`
  position: absolute;
  top: 35px;
  right: 40px;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media ${`(max-width: ${breakpoints.sm}px)`} {
    display: none;
  }
`

const CloseText = styled.span`
  color: ${colors.white};
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: -0.01em;
  text-align: left;
  margin-left: 10px;
`

const CloseX = styled(CloseText)`
  font-size: 22px;
  line-height: 27px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: all 250ms;
  overflow-y: scroll;
`

const TopContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;

  @media ${`(max-width: ${breakpoints.sm}px)`} {
    flex-direction: column-reverse;
  }
`

const Title = styled.span`
  font-family: ${fonts.codeProMono};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  margin: 2px;
`

const StaticText = styled(Title)`
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
`

const Name = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
`

const Image = styled.img`
  width 200px;
  height: 200px;
  margin: 20px;
`

const Description = styled.span`
  font-family: ${fonts.codeProMono};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  padding: 50px 20px;
`

type Props = {
  visible: boolean
  onAdditionalInfoClose: () => void
  fetching: boolean
  mediaId?: number
  additionalInfo: MediaFile | null
  isLive: boolean
}

export const MobileContent: FC<Props> = ({ fetching, mediaId, additionalInfo, visible, onAdditionalInfoClose, isLive }) => {
  if (fetching) return <CircularProgress />
  if (!additionalInfo && !isLive) {
    // Display error message if something is supposed to be playing but no info was received
    if (mediaId) return <span>Jokin meni pieleen ohjelmatietoja haettaessa :(</span>
    return <></>
  }
  return (
    <Content style={{ opacity: visible ? 1 : 0 }}>
      <CloseButton onClick={onAdditionalInfoClose}>
        <CloseX>X</CloseX>
        <CloseText>sulje</CloseText>
      </CloseButton>
      <TopContainer>
        {additionalInfo?.mediaSeries && (
          <>
            <Title>{additionalInfo.mediaSeries.name}</Title>
            <StaticText>Ohjelmasarja</StaticText>
          </>
        )}
        {additionalInfo?.imageSrc && <Image src={additionalInfo.imageSrc} />}
        {additionalInfo?.title && (
          <>
            <StaticText>Jakso</StaticText>
            <Name>{additionalInfo.title}</Name>
          </>
        )}
      </TopContainer>
      <MobileMediaPlayer />
      {additionalInfo?.description && <Description>{decodeURIComponent(additionalInfo.description)}</Description>}
    </Content>
  )
}
