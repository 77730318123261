import { types } from 'mobx-state-tree'
import React from 'react'
import { getMediaFileByIds } from '../services/api'
import { MediaFile } from '../types'

export const MediaPlayer = types
  .model({
    isPlaying: types.boolean,
    url: types.optional(types.string, ''),
    isLive: types.boolean,
    liveStreamIndex: types.optional(types.number, 0),
    liveStreamLabel: types.optional(types.string, ''),
    showName: types.optional(types.string, ''),
    episodeName: types.optional(types.string, ''),
    mediaId: types.optional(types.number, -1),
    mediaDuration: types.optional(types.number, -1),
    pauseRequested: types.boolean,
    paused: types.optional(types.boolean, false),
    resumeRequested: types.optional(types.boolean, false),
    existingProgress: types.optional(types.number, 0),
    skipRequested: types.optional(types.boolean, false),
    mediaSeriesId: types.maybe(types.number),
    mediaPlayerRef: types.maybe(types.model({}).volatile(self => ({})))
  })
  .actions((self) => {

    let playerRef: any = null;

    const streamURLMap = {
      0: 'https://stream.dei.fi/teema',
      1: 'https://stream.dei.fi:8443/yleisohjelma',
      2: 'https://stream.dei.fi:8443/helsinki',
      3: 'https://stream.dei.fi:8443/kajaani',
      4: 'https://stream.dei.fi:8443/tornio',
      5: 'https://stream.dei.fi:8443/kruunupyy',
      6: 'https://stream.dei.fi:8443/kristiinankaupunki',
      7: 'https://stream.dei.fi:8443/lahti',
      8: 'https://stream.dei.fi:8443/oulu',
      9: 'https://stream.dei.fi:8443/turku',
      10: 'https://stream.dei.fi:8443/rovaniemi',
      11: 'https://stream.dei.fi:8443/lapua',
      12: 'https://stream.dei.fi:8443/turku'
    }

    const setStreamURL = (val: string) => {
      self.url = val
    }

    const setIsPlaying = (val?: boolean) => {
      self.isPlaying = true;

      console.log('IS PLAYING, PLAYER REF', playerRef);

      // if (self.isPlaying && playerRef?.current)
      //   playerRef.play();

    }

    const playThrough = () => {
      console.log('playThrough called');
      document.querySelector('audio')?.play().then(() => {
        console.log('Second play')
        document.querySelector('audio')?.play();
      }).catch((e) => {
        console.log('ERROR FROM START', e);
        document.querySelector('audio')?.play();
      });
      if (playerRef) {
        console.log('player ref exists')
        if (playerRef.current) {
          console.log('current player ref exists')
          if (playerRef.current.audio) {
            console.log('audio ref exists');
            playerRef.current.audio.play();
          }
        }
      }

      if (self.mediaPlayerRef) {
        console.log('self media player works');
        // @ts-expect-error
        if (self.mediaPlayerRef.current) {
          console.log('self media player current is existing');
          // @ts-expect-error
          if (self.mediaPlayerRef?.current?.audio) {
            console.log('self media player audio yes?');
          }

        }
      }
    }

    const togglePlay = () => {

      self.isPlaying = !self.isPlaying;

      console.log('REF IN YES')
      console.log(playerRef, ' ref player sotre')
      // self.mediaPlayerRef?.current?.play();

      // playerRef?.current.play();

      // document.querySelector('audio')?.play();
      // playThrough();
    }

    const getStreamNameFromIndex = (index: number) => {
      const streamNameMap = {
        0: 'Missioradio',
        1: 'Radio Dei - pääkanava',
        2: 'Helsinki',
        3: 'Kajaani',
        4: 'Kemi',
        5: 'Kokkola',
        6: 'Kristiinankaupunki',
        7: 'Lahti',
        8: 'Oulu',
        9: 'Pori',
        10: 'Rovaniemi',
        11: 'Seinäjoki',
        12: 'Turku'
      }

      //@ts-expect-error
      return streamNameMap[index]
    }

    const getStreamUrlFromIndex = (index: number) => {
      const streamURLMap = {
        0: 'https://stream.dei.fi/teema',
        1: 'https://stream.dei.fi:8443/yleisohjelma',
        2: 'https://stream.dei.fi:8443/helsinki',
        3: 'https://stream.dei.fi:8443/kajaani',
        4: 'https://stream.dei.fi:8443/tornio',
        5: 'https://stream.dei.fi:8443/kruunupyy',
        6: 'https://stream.dei.fi:8443/kristiinankaupunki',
        7: 'https://stream.dei.fi:8443/lahti',
        8: 'https://stream.dei.fi:8443/oulu',
        9: 'https://stream.dei.fi:8443/turku',
        10: 'https://stream.dei.fi:8443/rovaniemi',
        11: 'https://stream.dei.fi:8443/lapua',
        12: 'https://stream.dei.fi:8443/turku'
      }

      //@ts-expect-error
      return streamURLMap[index]
    }

    const setPlayerRef = (playerRef: any) => {
      self.mediaPlayerRef = playerRef;
      playerRef = playerRef;

      //@ts-expect-error

      console.log(self.mediaPlayerRef?.current?.audio, 'self player refernce after setting it');
      // playerRef.current.onCanPlayThrough = () => {
      //   playerRef.play();
      // }

    }

    const switchStream = (newIndex: number) => {
      console.log('Got to switch streams!')
      //@ts-expect-error
      const newStreamURL: string = streamURLMap[newIndex]
      setStreamURL(newStreamURL)

      self.liveStreamLabel = getStreamNameFromIndex(newIndex)
      self.liveStreamIndex = newIndex
      self.showName = ''
      self.episodeName = ''
      self.isLive = true
      if (!self.isPlaying) {
        self.isPlaying = true
      }
    }

    const setLive = (newStatus: boolean) => {
      self.isLive = newStatus
    }

    const setProgress = (newProg: number) => {
      self.existingProgress = newProg
      self.skipRequested = true
    }

    const clearProgress = () => {
      self.existingProgress = 0
      self.skipRequested = false
    }

    const setShowName = (newShow: string) => {
      self.showName = newShow
    }

    const setEpisodeName = (newEpisode: string) => {
      self.episodeName = newEpisode
    }

    const setMediaId = (newMediaId: number) => {
      console.log('SET MEDIA ID CALLED')
      self.mediaId = newMediaId
    }

    const setMediaDuration = (mediaDuration: number) => {
      self.mediaDuration = mediaDuration
    }

    const requestPause = (value: boolean) => {
      self.pauseRequested = value
      console.log('REQUEST PAUSE CALL', value)
    }

    const requestResume = (value: boolean) => {
      self.resumeRequested = value
    }

    const setPause = (value: boolean) => {
      self.paused = value
      self.pauseRequested = value
      console.log('SET PAUSE CALL', value)
    }

    const setMediaSeriesId = (value: number) => {
      self.mediaSeriesId = value
    }

    const getMediaFile = async (id: string, mediaSeriesId: string): Promise<MediaFile | null> => {
      const res = await getMediaFileByIds(id, mediaSeriesId)
      if (res.status !== 200) return null
      return res.data
    }

    return {
      setStreamURL,
      setIsPlaying,
      togglePlay,
      switchStream,
      getStreamUrlFromIndex,
      getStreamNameFromIndex,
      setLive,
      setShowName,
      setEpisodeName,
      setMediaId,
      requestPause,
      setPause,
      requestResume,
      setProgress,
      clearProgress,
      setMediaDuration,
      setMediaSeriesId,
      getMediaFile,
      setPlayerRef,
      playThrough
    }
  })
