/* eslint-disable max-len */

export const colors = {
  brand: '#FFFFFF',
  header: '#000000',
  lilac: '#9d7cbf',
  accent: '#ffb238',
  success: '#37b635',
  warning: '#ec1818',
  ui: {
    bright: '#e0d6eb',
    light: '#f5f3f7',
    whisper: '#fbfafc',
    hrcolor: '#595959'
  },
  code: '#fcf6f0',
  gray: {
    dark: 'hsla(270, 17.119554496%, 0%, 0.92)',
    copy: 'hsla(270, 15.797828016000002%, 0%, 0.88)',
    calm: 'rgba(0, 0, 0, 0.54)',
    ligh: '#EFEFEF',
    darker: 'rgba(0, 0, 0, 0.8)',
    silver: '#C4C4C4'
  },
  radiochannels: {
    1: '#CE0031',
    2: 'rgb(118, 213, 179)',
    3: 'rgb(203, 45, 104)',
    4: 'rgb(17, 54, 117)',
    5: 'rgb(247, 201, 84)',
    6: 'rgb(178, 46, 90)',
    7: 'rgb(42, 107, 145)',
    8: 'rgb(28, 72, 76)',
    9: 'rgb(104, 222, 128)',
    10: 'rgb(239, 135, 51)',
    11: 'rgb(37, 35, 74)',
    12: 'rgb(222, 76, 65)'
  },
  white: '#fff',
  black: '#000',
  red: '#AA1E36',
  deiRed: '#CE0031',
}

export const fonts = {
  sansSerif:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif',
  serif: 'Georgia, "Times New Roman", Times, serif',
  monospace: 'Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace, monospace',
  montserrat: "Montserrat",
  codeProMono: '"Source Code Pro", monospace',
  ptMono: "PT Mono",
}

export const breakpoints = {
  xs: 480,
  sm: 640,
  md: 850,
  lg: 1024,
  xl: 1280,
  xxl: 1686,
  xxxl: 1820,
}

export const widths = {
  xs: 480,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
}

export const dimensions = {
  fontSize: {
    regular: 16,
    large: 18
  },
  headingSizes: {
    h1: 2.441,
    h2: 1.953,
    h3: 1.563,
    h4: 1.25
  },
  lineHeight: {
    regular: 1.45,
    heading: 1.2
  },
  containerPaddingHuge: 21.5,
  containerPaddingXl: 10,
  containerPadding: 6,
  containerPaddingSmall: 13,
  headerPadding: 5,
  mobileContianerPadding: 3,
  mobileContianerPaddingLarge: 4,
  containerPaddingVertical: 1.25,
}

export const heights = {
  header: 120,
  mobileHeader: 160,
}
