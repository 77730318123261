import * as React from 'react'
import { Global, css } from '@emotion/core'
import styled from 'styled-components'
import normalize from '../styles/normalize'
import { StoreProvider, rootStore, useMst } from '../models/Root'
import useIsClient from './RehydrationFixer/RehydrationFixer'
import LayoutMain from './LayoutMain'
import { Helmet } from 'react-helmet'
import { observer } from 'mobx-react-lite'
import { CircularProgress } from '@material-ui/core'
import { colors } from '../styles/variables'
import VideoPlayer from '../components/VideoPlayer'
import { useVideoPlayer } from '../utils/VideoPlayerContext'

const StyledLayoutRoot = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const LoadingContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  color: colors.deiRed,
  transition: 'all 250ms ease-in-out'
})

interface LayoutRootProps {
  className?: string
}

const LayoutRoot: React.FC<LayoutRootProps> = observer(({ children, className }) => {
  const { videoOpen, setVideoOpen, videoUrl } = useVideoPlayer()

  const {
    favorites: { initializeFavorites, state: fState },
    progress: { initializeProgress, state: pState },
    userStore: { getUserInfo }
  } = useMst()

  // Initialize whatever data would be good to have on hand on app startup
  React.useEffect(() => {
    const handleInit = async () => {
      await getUserInfo()
      await initializeFavorites()
      await initializeProgress()
    }
    handleInit()
  }, [initializeFavorites, getUserInfo])

  const { isClient, key } = useIsClient()

  if (!isClient || fState !== 'Done' || pState !== 'Done') {
    return (
      <LoadingContainer>
        <CircularProgress color="inherit" />
      </LoadingContainer>
    )
  }

  return (
    <div style={{ marginBottom: '150px' }} key={key}>
      <Helmet>
        <script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid="06758b25-5371-408c-8cec-2a4df11f76a1"
          data-blockingmode="auto"
          type="text/javascript"
        ></script>
        <style>{` #CybotCookiebotDialogPoweredbyCybot, #CybotCookiebotDialogPoweredByText {display: none !important;} `}</style>
      </Helmet>
      <StoreProvider value={rootStore}>
        <LayoutMain>
          <Global styles={() => css(normalize)} />
          <StyledLayoutRoot className={className}>
            <VideoPlayer url={videoUrl ?? ''} videoOpen={videoOpen} setVideoOpen={(value) => setVideoOpen(value)} />
            {children}
          </StyledLayoutRoot>
        </LayoutMain>
      </StoreProvider>
    </div>
  )
})

export default LayoutRoot
