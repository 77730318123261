// @ts-nocheck
import ThemesIcon from '../../resources/icons/themes-icon.svg'
import ChannelsIcon from '../../resources/icons/channels-icon.svg'
import PodcastsIcon from '../../resources/icons/podcasts-icon.svg'
import FavoritesIcon from '../../resources/icons/favorite-star-icon.svg'
import { colors } from '../../styles/variables'

export const links = (isMobile: boolean) => {
  const links = [
    { url: '/teemat/', text: 'Ohjelmat' },
    { url: '/radiokanavat/', text: isMobile ? 'Radiot' : 'Radiot' },
    { url: '/videot/', text: 'Videot' },
    { url: '/podcastit/', text: 'Podcastit' },
    { url: '/suosikit/', text: isMobile ? 'Omat' : 'Omat suosikit' }
  ]

  return links
}
