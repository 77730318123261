// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-arkisto-tsx": () => import("./../../../src/pages/arkisto.tsx" /* webpackChunkName: "component---src-pages-arkisto-tsx" */),
  "component---src-pages-hakutulokset-tsx": () => import("./../../../src/pages/hakutulokset.tsx" /* webpackChunkName: "component---src-pages-hakutulokset-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-listasivu-tsx": () => import("./../../../src/pages/listasivu.tsx" /* webpackChunkName: "component---src-pages-listasivu-tsx" */),
  "component---src-pages-ohjelmakartta-tsx": () => import("./../../../src/pages/ohjelmakartta.tsx" /* webpackChunkName: "component---src-pages-ohjelmakartta-tsx" */),
  "component---src-pages-ohjelmasarja-tsx": () => import("./../../../src/pages/ohjelmasarja.tsx" /* webpackChunkName: "component---src-pages-ohjelmasarja-tsx" */),
  "component---src-pages-page-2-tsx": () => import("./../../../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-palauta-salasana-tsx": () => import("./../../../src/pages/palauta-salasana.tsx" /* webpackChunkName: "component---src-pages-palauta-salasana-tsx" */),
  "component---src-pages-podcastit-tsx": () => import("./../../../src/pages/podcastit.tsx" /* webpackChunkName: "component---src-pages-podcastit-tsx" */),
  "component---src-pages-radiokanavat-tsx": () => import("./../../../src/pages/radiokanavat.tsx" /* webpackChunkName: "component---src-pages-radiokanavat-tsx" */),
  "component---src-pages-sisalto-tsx": () => import("./../../../src/pages/sisalto.tsx" /* webpackChunkName: "component---src-pages-sisalto-tsx" */),
  "component---src-pages-suosikit-tsx": () => import("./../../../src/pages/suosikit.tsx" /* webpackChunkName: "component---src-pages-suosikit-tsx" */),
  "component---src-pages-teemat-tsx": () => import("./../../../src/pages/teemat.tsx" /* webpackChunkName: "component---src-pages-teemat-tsx" */),
  "component---src-pages-varmistus-tsx": () => import("./../../../src/pages/varmistus.tsx" /* webpackChunkName: "component---src-pages-varmistus-tsx" */),
  "component---src-pages-videot-tsx": () => import("./../../../src/pages/videot.tsx" /* webpackChunkName: "component---src-pages-videot-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

