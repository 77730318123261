import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import Dropdown, { Option } from 'react-dropdown'
import 'react-dropdown/style.css'
import { useMst } from '../../models/Root'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'

require('./LCD.css')

const StyledDropdown = styled(Dropdown)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  white-space: nowrap;
  color: #ffffff;
  height: 43px;
  max-width: ${(props: ListenButtonProps) => (!props.isDesktop && !props.isPlayer ? '43px' : `unset`)};
  background: #000000;
  border-radius: 100px;
  margin-left: 20px;
  background-image: ${(props: ListenButtonProps) => (!props.isPlayer ? `url('/icons/playbutton_no_bg.svg')` : `unset`)};
  background-repeat: no-repeat;
  background-position-y: 12px;
  background-position-x: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

// cursor: pointer;
// @ts-ignore
interface Props {
  player: Boolean
  desktop: Boolean
}

// @ts-ignore
interface ListenButtonProps {
  isPlayer: Boolean
  isDesktop: Boolean
}

const LiveChannelDropdown: React.FC<Props> = observer((props) => {
  const { mediaPlayer } = useMst()
  const [value, setValue] = useState({ value: '', label: 'Kuuntele Radio Deitä' })
  const isDesktop = useMediaQuery({ minWidth: 768 })

  const playClicked = () => {
    // mediaPlayer.togglePlay();
    // if (value.value === '')
    //   setValue({ value: '0', label: 'Radio Dei - pääkanava' });
  }

  useEffect(() => {
    if (Number.parseInt(value.value) !== mediaPlayer.liveStreamIndex) {
      // console.log('LIVESTREAM INDEX CHANGED!', value.value, mediaPlayer.liveStreamIndex);
      setValue({ value: mediaPlayer.liveStreamIndex.toString(), label: mediaPlayer.liveStreamLabel })
    }
  }, [mediaPlayer.liveStreamIndex])

  const dropdownSelectionChanged = (newSelection: Option) => {
    console.log('New selection is: ', newSelection)
    mediaPlayer.switchStream(Number.parseInt(newSelection.value))
    // @ts-expect-error
    setValue({ value: newSelection.value, label: newSelection.label.toString() })
  }

  // const options = [
  //   'Radio Dei - pääkanava', 'Helsinki', 'Kajaani', 'Kemi', 'Kokkola', 'Kristiinankaupunki', 'Lahti', 'Oulu', 'Pori', 'Rovaniemi', 'Seinäjoki', 'Turku'
  // ];
  const options = [
    { value: 0, label: 'Radio Dei - pääkanava', className: 'LCD-option' },
    { value: 1, label: 'Helsinki', className: 'LCD-option' },
    { value: 2, label: 'Kajaani', className: 'LCD-option' },
    { value: 3, label: 'Kemi', className: 'LCD-option' },
    { value: 4, label: 'Kokkola', className: 'LCD-option' },
    { value: 5, label: 'Kristiinankaupunki', className: 'LCD-option' },
    { value: 6, label: 'Lahti', className: 'LCD-option' },
    { value: 7, label: 'Oulu', className: 'LCD-option' },
    { value: 8, label: 'Pori', className: 'LCD-option' },
    { value: 9, label: 'Rovaniemi', className: 'LCD-option' },
    { value: 10, label: 'Seinäjoki', className: 'LCD-option' },
    { value: 11, label: 'Turku', className: 'LCD-option' }
  ]

  return (
    <StyledDropdown
      isDesktop={isDesktop}
      isPlayer={props.player}
      menuClassName={props.player ? 'LCD-menu-player' : 'LCD-menu'}
      controlClassName={props.player ? 'LCD-control-player' : 'LCD-control'}
      arrowClassName={'LCD-arrow'}
      // @ts-expect-error
      options={options}
      value={value}
      placeholder={isDesktop ? 'Kuuntele Radio Deitä' : ''}
      onChange={(arg) => dropdownSelectionChanged(arg)}
    />
  )
})

export { LiveChannelDropdown }
