/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react';
import { VideoPlayerProvider } from './src/utils/VideoPlayerContext';

export const wrapRootElement = ({ element }) => {
  return <VideoPlayerProvider>{element}</VideoPlayerProvider>;
};

export const onServiceWorkerUpdateReady = () => window.location.reload();

export const onRouteUpdate = ({ location }) => scrollToAnchor(location);

const scrollToAnchor = (location, mainNavHeight = 0) => {
  // Check for location so build does not fail
  setTimeout(() => {
    if (location && location.hash && typeof window !== undefined) {
      const item = document.querySelector(`${location.hash}`);
      if (item) {
        const itemTop = item.offsetTop;
        window.scrollTo({
          top: itemTop - mainNavHeight,
          behavior: "smooth",
        });
      }
    }
  }, 500);

  return true;
};
