import axios from 'axios'
import { rootStore } from '../../models/Root'
import { MediaSeriesType } from '../../models/Search'
import { CategoryPlacement, Favorite, Menu, Progress, ProgressQuery } from '../../types'
import apiErrorHandler from './apiErrorHandler'

export const API_PATHS = Object.freeze({
  mediaseries: {
    latest: '/mediaseries/newest?{queryparams}',
    latestAll: '/mediaseries/newestAll',
    category: '/mediaseries/{category}',
    categoryAll: '/mediaseries/{categoryAll}/all',
    categorySeries: '/mediaseries/{category}/series',
    categoryFiles: '/mediaseries/{category}/files',
    byId: '/mediaseries/id/{id}',
    byName: '/mediaseries/name/{name}',
    archive: '/mediaseries/archived',
    podcasts: '/rssFeeds/podcasts?{queryparams}',
    podcastSeries: '/rssFeeds/podcasts/series?{queryparams}',
    podcastFiles: '/rssFeeds/podcasts/files?{queryparams}'
  },
  mediafile: {
    byId: '/mediafiles/{id}'
  },
  categories: {
    byPage: '/categories?page={page}'
  },
  banners: {
    byPage: '/banners?page={page}',
    footer: '/banners/footer'
  },
  favorites: {
    get: '/favorites',
    add: '/favorites/add',
    getUser: (userId: number) => `/favorites/${userId}`,
    delete: '/favorites/delete?{params}'
  },
  popularity: '/popularity?{queryparams}',
  popularityList: '/popularity/list',
  search: '/search',
  getMenu: '/menu',
  getFooterLinks: '/menu/footerlinks',
  getStaticContent: '/staticpages/name/{name}/html',
  calendar: '/programmecalendar?{queryparams}',
  progress: {
    get: '/progress',
    add: '/progress/add',
    update: '/progress/update',
    getUser: (userId: number) => `/progress/${userId}`,
    delete: '/progress/delete?{params}'
  },
  user: {
    me: '/users/me',
    forgotPassword: '/users/forgot-password',
    updatePassword: '/users/update-password'
  },
  auth: {
    login: '/auth/userLogin',
    externalLogin: '/auth/external-login',
    register: '/auth/register',
    logout: '/auth/logout',

    verifyEmail: '/auth/verifyEmail'
  },
  video: {
    byId: '/videos/series/{channel}',
  },
  subscribeNewsLetter: '/newsLetter',
})

console.log('AXIOS CREATION', process.env.REACT_API_URL)

export const api = axios.create({
  baseURL: process.env.GATSBY_API_URL || process.env.REACT_API_URL,
  timeout: 20000
})

// Errors
api.interceptors.response.use((response) => response, apiErrorHandler)

api.interceptors.request.use((config) => {
  if (typeof window !== 'undefined' && window.sessionStorage) {
    const accessToken = rootStore.authStore.token
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`
  }
  return config
})

export interface MediaQuery {
  id: number
  series: boolean
}

export const getCategoriesForPage = async (page: string) => {
  return api.get<CategoryPlacement[]>(API_PATHS.categories.byPage.replace('{page}', page))
}

export const getBannersForPage = async (page: string) => {
  return api.get(API_PATHS.banners.byPage.replace('{page}', page))
}

export const getLatestMediaSeries = async (skip?: number, take?: number) => {
  return api.get(API_PATHS.mediaseries.latest.replace('?{queryparams}', '') + `?skip=${skip}&take=${take}`)
}

export const getLatestMediaSeriesAll = async (skip?: number, take?: number) => {
  return api.get(API_PATHS.mediaseries.latestAll + `?skip=${skip}&take=${take}`)
}

export const getCategoryData = async (category: string, skip?: number, take?: number) => {
  const path = API_PATHS.mediaseries.categoryAll.replace('{categoryAll}', category) + `?skip=${skip}&take=${take}`

  return api.get(path)
}

export const getCategoryLatestData = async (category: string) => {
  const path = API_PATHS.mediaseries.category.replace('{category}', category)

  return api.get(path)
}

export const getMediaSeriesById = async (mediaSeriesID: string, mediaType: string, skip?: number, take?: number) => {
  const path = API_PATHS.mediaseries.byId.replace('{id}', mediaSeriesID + `?mediaType=${mediaType}&skip=${skip}&take=${take}`)

  return api.get(path)
}

export const getMediaFileByIds = async (mediaFileId: string, mediaSeriesId: string) => {
  const path = API_PATHS.mediafile.byId.replace('{id}', mediaFileId)

  return api.get(path, { params: { mediaSeriesId } })
}

export const getMediaData = async (favoritesData: MediaQuery[]): Promise<MediaSeriesType[] | null> => {
  const path = API_PATHS.favorites.get
  const res = await api.post(path, { data: favoritesData })
  return res.data
}

export const submitPopularityPulse = async (mediaId: number) => {
  const path = API_PATHS.popularity

  return api.post(path, { mediaId })
}

export const getRecommendedData = async (skip?: number, take?: number) => {
  const path = API_PATHS.popularityList
  return api.get(path + `?skip=${skip}&take=${take}`)
}

export const getArchiveData = async (skip?: number, take?: number) => {
  const path = API_PATHS.mediaseries.archive

  return api.get(path)
}

export const getSearchResults = async (query: string, skip?: number, take?: number) => {
  const path = API_PATHS.search;
  const params: { [key: string]: string | number } = { q: query };

  if (skip !== undefined) {
    params['skip'] = skip;
  }

  if (take !== undefined) {
    params['take'] = take;
  }

  return api.post(path, params);
};
export const getPodcasts = async (skip?: number, take?: number) => {
  const path = API_PATHS.mediaseries.podcasts.replace('{queryparams}', `skip=${skip}&take=${take}`)

  return api.get(path)
}

export const getPodcastSeries = async (skip?: number, take?: number) => {
  const path = API_PATHS.mediaseries.podcastSeries.replace('{queryparams}', `skip=${skip}&take=${take}`)

  return api.get(path)
}

export const getPodcastFiles = async (skip?: number, take?: number) => {
  const path = API_PATHS.mediaseries.podcastFiles.replace('{queryparams}', `skip=${skip}&take=${take}`)

  return api.get(path)
}

export const getMenu = async () => {
  const path = API_PATHS.getMenu

  return api.get(path)
}

export const getStaticPageContent = async (name: string) => {
  const path = API_PATHS.getStaticContent

  return api.get(path.replace('{name}', name))
}

export const getCategorySeries = async (category: string, skip?: number, take?: number) => {
  const path = API_PATHS.mediaseries.categorySeries.replace('{category}', category) + `?skip=${skip}&take=${take}`

  return api.get(path)
}

export const getCaterofyFiles = async (category: string, skip?: number, take?: number) => {
  const path = API_PATHS.mediaseries.categoryFiles.replace('{category}', category) + `?skip=${skip}&take=${take}`

  return api.get(path)
}

export const getProgrammCalendar = async (week: number) => {
  const path = API_PATHS.calendar.replace('{queryparams}', `week=${week}`)

  return api.get(path)
}

export const getMediaSeriesByName = async (name: string) => {
  const path = API_PATHS.mediaseries.byName.replace('{name}', name)

  return api.get(path)
}

export const getFooterBanner = async () => {
  const path = API_PATHS.banners.footer

  return api.get(path)
}

export const getFooterLinks = async () => {
  const path = API_PATHS.getFooterLinks

  return api.get<Menu[]>(path)
}

export const getUserFavorites = async (userId: number): Promise<Favorite[] | null> => {
  const res = await api.get<Favorite[]>(API_PATHS.favorites.getUser(userId))
  return res.data
}

export const addToFavorites = async (userId: number, mediaId: number, isSeries: boolean) => {
  const path = API_PATHS.favorites.add
  return await api.post(path, { userId, mediaId, isSeries })
}

export const deleteUserFavorite = async (userId: number, mediaId: number, isSeries: boolean) => {
  const path = API_PATHS.favorites.delete
  return await api.delete(path.replace('{params}', `userId=${userId}&mediaId=${mediaId}&isSeries=${isSeries}`), {})
}

export const getUserInProgress = async (userId: number): Promise<Progress[] | null> => {
  const res = await api.get<Progress[]>(API_PATHS.progress.getUser(userId))
  return res.data
}

export const addToInProgress = async (userId: number, mediaId: number, position: number) => {
  const path = API_PATHS.progress.add
  return await api.post(path, { userId, mediaId, position })
}

export const deleteInProgress = async (userId: number, mediaId: number) => {
  const path = API_PATHS.progress.delete
  return await api.delete(path.replace('{params}', `userId=${userId}&mediaId=${mediaId}`), {})
}

export const updateInProgress = async (userId: number, mediaId: number, position: number) => {
  const path = API_PATHS.progress.update
  return await api.post(path, { userId, mediaId, position })
}

export const subscribeNewsLetter = async (email: string) => {
  const path = API_PATHS.subscribeNewsLetter
  return await api.post(path, { email })
}
